<template>
  <v-container>
    <v-row v-if="ready">
      <spinner style="margin: 400px 400px;"></spinner>
    </v-row>

    <v-row class="mx-2" style="margin-top: 30px" v-else></v-row>

  </v-container>
</template>

<script>
import Spinner from "vue-simple-spinner";
import axios from "axios";



export default {
name: "ChequeDetails",
  components: {Spinner},





  methods: {

    getDetails(){
      let id = this.$route.params.id;
      console.log(id);
      this.ready = true;
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/orders/line/item/${id}`
      })
          .then(response => {
            console.log(response);
            this.ready = false;
            if (response.status === 200) {
              this.details = response.data;
            }
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.$router.replace({ name: "/login" });
            } else {
              //console.log(err);
            }
          });
    },



  },


  created() {
    this.getDetails();
  }

}
</script>

<style scoped>

</style>